<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import {
  required, maxLength
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Nouveau projet",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouveau projet",
      uoms: [],
      categories: [],
      waerhouses: [],
      contracts: [],
      items: [
        {
          text: "Misc",
          href: "/"
        },
        {
          text: "Projets",
          href: "/"
        },
        {
          text: "Nouveau",
          active: true
        }
      ],
      projectForm: {
        name: "",
        description: "",
        analytic_account: "",
        location: "",
        date_from: "",
        date_to: "",
        comments: "",
      },
      
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    projectForm: {
      name: {required, maxLength:maxLength(64)},
      analytic_account: {required, maxLength:maxLength(64)},
      location: {required, maxLength:maxLength(64)},
      date_from: {required},
      date_to: {required},
    },
    
  },
  methods: {
    formSubmit() {
      this.submitted = true;
      this.$v.$touch()
      if(!this.$v.$invalid){
      var router = this.$router;
      this.$http.post('/misc/projects/store', this.projectForm)

      .then((res) => {
          var status = res.data.original.status;
          switch(status){
            case 200: 
              this.$toast.success(res.data.original.msg);
              router.push({ name: 'base.misc.projects' });
              
            break;

            case 500: 
              this.$toast.warning(res.data.original.msg);
            break;
          }
      })
      .catch((error) => {
          this.$toast.error(error.message);
      }).finally(() => {
      });
    }
    },

   
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <b-alert show variant="info">
                <i class="mdi mdi-alert-circle-outline mr-2"></i>Les champs
                obligatoires sont marqués avec (*)
              </b-alert>
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Désignation *</label>
                    <input
                      id="deptName"
                      v-model="projectForm.name"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.projectForm.name.$error }"
                    />
                    <div v-if="submitted && $v.projectForm.name.$error" class="invalid-feedback">
                      <span v-if="!$v.projectForm.name.required">Le nom est obligatoire.</span>
                      <span v-if="!$v.projectForm.name.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Compte analytique *</label>
                    <input
                      id="deptName"
                      v-model="projectForm.analytic_account"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.projectForm.analytic_account.$error }"
                    />
                    <div v-if="submitted && $v.projectForm.analytic_account.$error" class="invalid-feedback">
                      <span v-if="!$v.projectForm.analytic_account.required">Le compte analytique est obligatoire.</span>
                      <span v-if="!$v.projectForm.analytic_account.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Site *</label>
                    <input
                      id="deptName"
                      v-model="projectForm.location"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.projectForm.location.$error }"
                    />
                    <div v-if="submitted && $v.projectForm.location.$error" class="invalid-feedback">
                      <span v-if="!$v.projectForm.location.required">Le Site est obligatoire.</span>
                      <span v-if="!$v.projectForm.location.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Date début *</label>
                    <input
                      id="deptName"
                      v-model="projectForm.date_from"
                      type="date"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.projectForm.date_from.$error }"
                    />
                    <div v-if="submitted && $v.projectForm.date_from.$error" class="invalid-feedback">
                      <span v-if="!$v.projectForm.date_from.required">La date début est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="deptName">Date fin *</label>
                    <input
                      id="deptName"
                      v-model="projectForm.date_to"
                      type="date"
                      class="form-control"
                      placeholder=""
                      value=""
                      :class="{ 'is-invalid': submitted && $v.projectForm.date_to.$error }"
                    />
                    <div v-if="submitted && $v.projectForm.date_to.$error" class="invalid-feedback">
                      <span v-if="!$v.projectForm.date_to.required">La date fin est obligatoire.</span>
                    </div>
                  </div>
                </div>
                
              </div>
              
              
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Commentaires</label>
                    <textarea class="form-control" id="" cols="30" rows="10"></textarea>
                    
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>